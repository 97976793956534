import React, { useState, createContext, useContext, useEffect } from 'react';
import { getFirestore, collection, getDocs} from 'firebase/firestore';

const BookingContext = createContext([])

export const useBookingContext = () => useContext(BookingContext)


const BookingContextProvider = ({ children }) => {

    const db = getFirestore()

    const [bookings, setBookings] = useState([])
    const [vouchers, setVouchers] = useState([])
    const [requests, setRequests] = useState([])
    const [render, setRender] = useState(false)
    const [contextBookings, setContextBookings] = useState([])
  
    

    const rerender = () => {
      if(render === true) {
        setRender(false)
      } else {
        setRender(true)
      }
     }
  
    useEffect(() => {
      const QueryCollection = collection(db,`bookings`)
  
      getDocs(QueryCollection)
        .then(resp => setBookings(resp.docs.map(book => ({ id: book.id, ...book.data() }))))
        .catch((err) => console.log(err))
  
     }, [render])

     useEffect(() => {
      const QueryCollection = collection(db,`vouchers`)
  
      getDocs(QueryCollection)
        .then(resp => setVouchers(resp.docs.map(book => ({ id: book.id, ...book.data() }))))
        .catch((err) => console.log(err))
  
     }, [render])
  
     useEffect(() => {
      const QueryCollection = collection(db,`requests`)
  
      getDocs(QueryCollection)
        .then(resp => setRequests(resp.docs.map(book => ({ id: book.id, ...book.data() }))))
        .catch((err) => console.log(err))
  
     }, [render])
  


    return (
        <BookingContext.Provider value={{
          contextBookings,
            bookings,
            rerender,
            requests,
            vouchers
        }}>
            {children}

        </BookingContext.Provider>
    )

}

export default BookingContextProvider