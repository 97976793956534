import React, { useState, useEffect } from "react";
import {
  collection,
  getFirestore,
  getDocs,
  writeBatch,
  where,
  query,
  documentId,
} from "firebase/firestore";

import "./../../App.css";
import { useBookingContext } from "../context/BookingContext";
import { useRoomsContext } from "../context/RoomsContext";
import { LineWobble } from "@uiball/loaders";
import { Link } from "react-router-dom";
import BookCont from "../book/BookCont";
import { InstagramEmbed } from "react-social-media-embed";
import CommentsList from "./CommentsList";
import NewComment from "./NewComment";
import CancelButton from "./CancelButton";
import NewCalendarCont from "../newCalendar/NewCalendarCont";
import Footer from "./Footer";

export default function MicrositesConts() {
  const db = getFirestore();

  const [comments, setComments] = useState([]);
  const { bookings, rerender } = useBookingContext();
  const [micrositeData, setMicrositeData] = useState([]);
  const [images, setImages] = useState();
  const [texts, setTexts] = useState();
  const [social, setSocial] = useState();
  const [commentModal, setCommentModal] = useState(false);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [toHome, setToHome] = useState(0);

  const toHomeF = () => {
    setToHome(toHome + 1);
  };

  useEffect(() => {
    setShowCalendar(false);
  }, [toHome]);

  useEffect(() => {
    const QueryCollection = collection(db, `data`);

    getDocs(QueryCollection)
      .then((resp) =>
        setData(resp.docs.map((book) => ({ id: book.id, ...book.data() })))
      )
      .catch();
  }, []);
  useEffect(() => {
    const QueryCollection = collection(db, `microsite`);

    getDocs(QueryCollection)
      .then((resp) =>
        setMicrositeData(
          resp.docs.map((amen) => ({ id: amen.id, ...amen.data() }))
        )
      )
      .catch();
  }, []);

  useEffect(() => {
    const QueryCollection = collection(db, `comments`);

    getDocs(QueryCollection)
      .then((resp) =>
        setComments(resp.docs.map((site) => ({ id: site.id, ...site.data() })))
      )
      .catch();
  }, []);

  const showCommentModal = () => {
    if (commentModal === true) {
      setCommentModal(false);
    } else {
      setCommentModal(true);
    }
  };

  const showCalendarF = () => {
    if (showCalendar === true) {
      setShowCalendar(false);
    } else {
      setShowCalendar(true);
    }
  };

  const quitCalendarF = () => {
    setShowCalendar(false);
  };

  useEffect(() => {
    const QueryCollection = collection(db, `microsite`);

    getDocs(QueryCollection)
      .then((resp) =>
        setMicrositeData(
          resp.docs.map((site) => ({ id: site.id, ...site.data() }))
        )
      )
      .catch();
  }, []);

  useEffect(() => {
    setTexts(micrositeData.find((data) => data.id == "texts"));
    setImages(micrositeData.find((data) => data.id == "images"));
    setSocial(micrositeData.find((data) => data.id == "social"));
  }, [micrositeData]);

  setTimeout(() => {
    setLoader(false);
  }, 3000);

  const showTermsF = () => {
    if (showTerms === true) {
      setShowTerms(false);
    } else {
      setShowTerms(true);
    }
  };



 


  const [asesoria, setAsesoria] = useState(false);

  const asesoriaTrue = () => {
    if (asesoria === false) {
      setAsesoria(true);
    } else {
      setAsesoria(false);
    }
  };

  const ancho = window.innerWidth;
  const alto = window.innerHeight;


  const [openNewsOpen, setOpenNewsOpen] = useState(false);

  const openNews = () => {
    setOpenNewsOpen(!openNewsOpen);
  };

  return (
    <>
      {loader === true ? (
        <div className="loader column">
          <Link to="/">
            <img
              className="logoMicrosite"
              src="https://firebasestorage.googleapis.com/v0/b/coqueteria-b7812.appspot.com/o/default%2Flogo.jpg?alt=media&token=8d13d404-2cbf-48d9-8e14-578f9f4f5b74"
              alt="logo"
            />
          </Link>

          <LineWobble size={300} lineWeight={8} speed={2.5} color="#0010d5" />
        </div>
      ) : (
        <>
          <div className="allSectionCont">
            <Link to="/">
              {images && (
                <img className="logoMicrosite" src={images.logo} alt="" />
              )}
            </Link>

            {showCalendar === true ? (
              <div className="micrositeCover">
                {/* <BookCont images={images} asesoria={asesoria} asesoriaTrue={asesoriaTrue} toHomeF={toHomeF} /> */}

                <NewCalendarCont
                  images={images}
                  asesoria={asesoria}
                  asesoriaTrue={asesoriaTrue}
                  toHomeF={toHomeF}
                  showCalendarF={showCalendarF}
                />
              </div>
            ) : (
              <>
                <section className="micrositeCover">
                  <img
                    className="imgCover"
                    src="https://firebasestorage.googleapis.com/v0/b/coqueteria-b7812.appspot.com/o/default%2Fgrain.jpg?alt=media&token=08bce4fa-4ab5-4f87-ada4-611e4230acb3"
                    alt=""
                  />

                  <div className="header">
                    <div
                      className={showCalendar === false ? "" : "displayNone"}
                    >
                      {/* <div className='alignCenterTopMap'>
                <img className='miniImage' src="https://img.icons8.com/ios-filled/100/0010d5/marker.png" alt="" />
                <p className='micrositeminitext'> {texts.city}</p>
              </div> */}

                      <h1 className="micrositeTittle ">
                        {" "}
                        hola<b className="inverseText">¡</b> estas en
                        laCoqueteria<b className="inverseText">¡</b>{" "}
                      </h1>

                      <p className="micrositeDescription">
                        {" "}
                        un estudio de intervención creativa del pelo.
                        consideramos tu ser integral para darle al componente
                        estético lo que representa.{" "}
                      </p>
                      <p className="micrositeTittle superminitextBogue">
                        atendido por valu
                      </p>
                        <div className="row1gap">
                        <button
                        onClick={showCalendarF}
                        className="button2Show "
                        to="#listcont"
                      >
                        {showCalendar === true
                          ? "volver atrás"
                          : "gestioná tu turno"}
                      </button>
                      {showCalendar === false ? (
                        <button
                          onClick={() => {
                            showCalendarF();
                            asesoriaTrue();
                          }}
                          className="button2Show "
                          to="#listcont"
                        >
                          {showCalendar === true
                            ? "volver atrás"
                            : "asesorías online"}

                        </button>
                      ) : (
                        <></>
                      )}
 <Link
               to="/voucher"
               className="button2Show "
     
             >
                                                      <img className="christmas2" width="30" height="30" src="https://img.icons8.com/color/48/santas-hat.png" alt="santas-hat"/>

             gift card
             </Link>
                        </div>
                     
                    </div>
                    {/* <button
                      onClick={openNews}
                      className={`buttonbyn pinkgradient ${
                        openNewsOpen ? "solid" : "beat"
                      }`}
                    >
                      <p className="marginzero">nuevo COQUEstudio!</p>

                      {openNewsOpen ? (
                        <p className=" marginzero">
                          <br />
                          📍 ¡NOS MUDAMOS! la nueva dirección es 59 #993 casi
                          esquina 15 (espacio de OtraCasaclub). <br />
                          <br />
                          〰️ la coqueteria esta en el piso de arriba. si tenes
                          dificultades para subir escaleras ¡avisanos! que nos
                          adaptamos a tu situación
                        </p>
                      ) : (
                        <p className="arrownnew marginzero">➭</p>
                      )}
                    </button> */}
                  </div>
                </section>

                <div className="section2  columnMobile">
                  <div className="commentListContDash">
                    {ancho > 600 ? (
                      <InstagramEmbed
                        url="https://www.instagram.com/p/CT0FmHUP2ty/"
                        width={400}
                        height={500}
                      />
                    ) : (
                      <InstagramEmbed
                        url="https://www.instagram.com/p/CT0FmHUP2ty/"
                        width={350}
                        height={450}
                      />
                    )}
                  </div>

                  <div className="commentListContDash">
                    <NewComment
                      newCommentModal={commentModal}
                      closeModal={showCommentModal}
                    />
                    <p className="micrositeTittle minitextBogue">
                      la <span className="blue">coquebanda</span> anda
                      diciendo...
                    </p>
                    <div className="commentsCont">
                      <CommentsList comments={comments} />
                    </div>
                    <button className="button" onClick={showCommentModal}>
                      ¡dejame tu comentario!
                    </button>
                  </div>
                </div>
              </>
            )}

            <Footer />
          </div>
        </>
      )}
    </>
  );
}
