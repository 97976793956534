import React, { useState, useEffect } from 'react'
import { collection, getFirestore, getDocs } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';



export default function VoucherModal({ modal, closeModal, bookCondition }) {
  const db = getFirestore()
  const { customer, roomedit } = useParams()

  const [paymentData, setPaymentData] = useState([])
  const [micrositeData, setMicrositeData] = useState([])


  const [bookOrder, setBookOrder] = useState([])
  const [show, setShow] = useState(false)
  const [name, setName] = useState()
  const [cbu, setCbu] = useState()
  const [alias, setAlias] = useState()
  const [bankname, setBankname] = useState()
  const [email, setEmail] = useState()




  useEffect(() => {
    const queryCol = collection(db, `profile`)

    getDocs(queryCol)
      .then(resp => setPaymentData(resp.docs.map(bdata => ({ id: bdata.id, ...bdata.data() }))))
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    const queryCol = collection(db, `microsite`)

    getDocs(queryCol)
      .then(resp => setMicrositeData(resp.docs.map(bdata => ({ id: bdata.id, ...bdata.data() }))))
      .catch((err) => console.log(err))
  }, [])


  const [bankData, setBankData] = useState()

  useEffect(() => {
    if (paymentData !== []) {
      setBankData(paymentData.find(pay => pay.id == "bankdata"))
    } else {
      setBankData(undefined)
    }
  }, [paymentData])

  useEffect(() => {

    if (bankData !== undefined) {
      setName(bankData.name)
      setCbu(bankData.cbu)
      setAlias(bankData.alias)
      setBankname(bankData.bankname)
    } else {
      setName("")
      setCbu("")
      setAlias("")
      setBankname("")
    }
  }, [bankData])



  useEffect(() => {

    let siteData = micrositeData.find(site => site.id == "texts")

    if (siteData !== undefined) {
      setEmail(siteData.email)
    } else {

    }

  }, [micrositeData])




  useEffect(() => {
    setBookOrder(JSON.parse(sessionStorage.getItem('bookClient')))




  }, [modal])

  //   const closeModal = () => {
  //     setShow(false)
  //     setBookOrder([])
  // }

  function printOrSave() {
    window.print()

  }



  return (
    <>
    {bankData && (
            <div className='bookingsModal'>

            <button className='closeModalWeek' onClick={closeModal}> Cerrar </button>
  
            <div className='dashboardBookOrderWeek'>
  
              <p className='modalText'>¡gracias! </p>
  
  
              <p className='modalText'> enviá el comprobante de transferencia al siguiente mail o por whatsapp:</p>
  
              <b className='modalText'>lacoqueteria.lp@gmail.com |<span>
              <Link className='row mobileColumn' to="https://api.whatsapp.com/send/?phone=5492213990248&text&type=phone_number&app_absent=0">  <img className='whatsappLogo' src="https://img.icons8.com/ios/50/76d273/whatsapp--v1.png" />      </Link></span> </b>
  
  
  
              {/* <div className='dateData'>
                <p className='modalText modalMini'>{bookOrder.client.phone}</p>
                <p className='modalText modalMini'> {bookOrder.client.email}</p>
              </div> */}
  
  
              {bankData !== undefined ?
                <div className='dateData column'>
                  <p className='button2'> <b>DATOS BANCARIOS</b> </p>
                  <p className=' bankDataTexts'> cbu: <b>{cbu}</b></p>
                  <p className=' bankDataTexts'> alias: <b>{alias}</b></p>
                  <p className=' bankDataTexts'> banco: <b>{bankname}</b></p>
                  <p className=' bankDataTexts'><b>{name}</b></p>
                </div>
                : <></>}
              <div>
  
              </div>
            </div>
  
          </div>

    )}

   

    </>
  )
}
