import React, { useState, useRef, useEffect } from 'react';
import { addDoc, collection, getFirestore, getDocs } from "firebase/firestore";
import { LineWobble } from "@uiball/loaders";
import emailjs from "@emailjs/browser";


import Footer from './Footer';
import { Link } from 'react-router-dom';
import "./../../App.css";
import BookedOrderModal from '../booking/BookedOrderModal';
import VoucherModal from '../booking/VoucherModal';

export default function Voucher() {
    const db = getFirestore();
    const formRef = useRef(null);
    
    // Form state
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [dni, setDni] = useState("");
    const [ig, setIg] = useState("");
    const [selectedAmount, setSelectedAmount] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const [booked, setBooked] = useState(false);
    const [payment, setPayment] = useState(null);
    const [modal, setModal] = useState(false)
    const openModal = () => {
        setModal(true);

        window.scrollTo(0, 0);
      };



    // Voucher options
    const voucherAmounts = [

      { value: 17000, label: "CORTE CON LA COQUETERIA ($17.000)" },
      { value: 21000, label: "CORTE CON LAVADO ($21.000)" },
      { value: 32000, label: "APRENDE A CUIDAR TU MELENA ($32.000)" },
      { value: 59000, label: "ILUMINACIÓN DE MELENAS ($59.000)" }
    ];
  
    const [formData, setFormData] = useState({
      customer: "La Coquetería",
      customerEmail: "lacoqueteria.lp@gmail.com",
      customerWhatsapp: "221-399-0248",
      customerAddress: "Calle 59 993, OtraCasaClub, La Plata",
      customerWebsite: "lacoqueteria.com.ar",
      to_name: "Valu",
      email_to: "lacoqueteria.lp@gmail.com",
      clientName: "",
      voucherAmount: "",
    });
  
    const handleSubmit = (event) => {
      event.preventDefault();
    };
    
  
    useEffect(() => {
      setFormData(prev => ({
        ...prev,
        email_to: email,
        clientName: name,
        service: selectedItem + " $" + selectedAmount,
        date: "VOUCHER",
        time: "VOUCHER"
      }));
    }, [email, name, selectedAmount, selectedItem]);
  
    const emailJsF = () => {
        emailjs
          .sendForm(
            "lacoqueteria",
            "template_1wiqe1a",
            formRef.current,
            "5HliEyZdhFxxuTp9H",
            { variables: formData }
          )
          .then((response) => {
            if (response.status === 200) {
            } else {
            }
          });
      };
  
    const handleVoucherPurchase = async () => {
      setLoading(true);
      const date = new Date();
  
      try {
        const voucherData = {
          idCode: parseInt((Math.random() * 10000000 + Math.random() * 10000000) / Math.random(1, 50)),
          client: { name, email, phone, dni, ig },
          amount: selectedAmount,
          item: selectedItem,
          paid: false,
          purchaseDate: {
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear(),
          },
          hour: `${date.getHours()}:${date.getMinutes()}`,
          status: true,
          timestamp: Date.now(),
          transfer: false
        };
  
        await addDoc(collection(db, "vouchers"), voucherData);
        emailJsF();
        setBooked(true);
        
      } catch (error) {
        console.error("Error during voucher purchase:", error);
      } finally {
        setLoading(false);
      }
    };
  
      const [images, setImages] = useState();
  const [micrositeData, setMicrositeData] = useState([]);

    useEffect(() => {
      const QueryCollection = collection(db, `microsite`);
  
      getDocs(QueryCollection)
        .then((resp) =>
          setMicrositeData(
            resp.docs.map((amen) => ({ id: amen.id, ...amen.data() }))
          )
        )
        .catch();
    }, []);

      
        useEffect(() => {

          setImages(micrositeData.find((data) => data.id == "images"));

        }, [micrositeData]);


        const selectionItem = (a , b) => {
            setSelectedAmount(a) 
            setSelectedItem(b)
        }
      
  return (
             <div className="allSectionCont">
               <Link to="/">
                 {images && (
                   <img className="logoMicrosite" src={images.logo} alt="" />
                 )}
               </Link>
   
            
                   <section className="micrositeCover">
                     {/* <img
                       className="imgCover"
                       src="https://firebasestorage.googleapis.com/v0/b/coqueteria-b7812.appspot.com/o/default%2Fgrain.jpg?alt=media&token=08bce4fa-4ab5-4f87-ada4-611e4230acb3"
                       alt=""
                     /> */}
     <div className="RoomCardsDetailCont">
      <h2 className="RoomCardsDetailTittle"> elegí la gift card de la coque</h2>
      
      <form ref={formRef} onSubmit={handleSubmit} className="formAgregarProducto">
        {/* Hidden form fields for EmailJS */}
        <div className="noShowForm">
          <input type="text" name="email_to" value={formData.email_to} readOnly />
          <input type="text" name="to_name" value={formData.to_name} readOnly />
          <input type="text" name="clientName" value={formData.clientName} readOnly />
          <input type="text" name="voucherAmount" value={formData.voucherAmount} readOnly />
          <input type="text" name="customer" value={formData.customer} readOnly />
          <input type="text" name="customerEmail" value={formData.customerEmail} readOnly />
          <input type="text" name="customerWhatsapp" value={formData.customerWhatsapp} readOnly />
          <input type="text" name="customerAddress" value={formData.customerAddress} readOnly />
          <input type="text" name="customerWebsite" value={formData.customerWebsite} readOnly />
        </div>

        {/* Voucher Selection */}
        <div className="chooseHaircutButtonCont">
          {/* <h2 className="RoomCardsDetailTittle">elegí el monto del voucher</h2> */}
          {voucherAmounts.map((amount) => (
            <button
              key={amount.value}
              type="button"
              onClick={() => selectionItem(amount.value, amount.label)}
              className={`chooseHaircutButton ${
                selectedAmount === amount.value ? "chooseHaircutButtonColor" : ""
              }`}
            >
              {amount.label}
            </button>
          ))}
        </div>


        {selectedAmount && (
          <div className="ContactInfoCont ">
            <p className="RoomCardsDetailTittle lighter">dejá tus datos para el voucher!</p>
            <p className="RoomCardsDetailDescription positionR">* requerido</p>
            
            <input 
              type="text"
              placeholder="Nombre y apellido *"
              onChange={(e) => setName(e.target.value)}
            className='inputCheckOut'
            />
            <input
              type="email"
              placeholder="Email *"
              onChange={(e) => setEmail(e.target.value)}
           className='inputCheckOut'
            />
            <input
              type="tel"
              placeholder="Teléfono *"
              onChange={(e) => setPhone(e.target.value)}
             className='inputCheckOut'
            />
            <input
              type="text"
              placeholder="DNI/ID *"
              onChange={(e) => setDni(e.target.value)}
          className='inputCheckOut'
            />
            <input
              type="text"
              placeholder="Instagram *"
              onChange={(e) => setIg(e.target.value)}
    className='inputCheckOut zindex1000000'
            />


                <div className='column'>
                {name && email && phone && dni && ig && !booked && (
              <button
                type="submit"
                onClick={handleVoucherPurchase}
                disabled={loading}
                className="submitButton margintop"
              >
                {loading ? (
                  <div className="checkedoutBook">
                    <LineWobble size={80} lineWeight={5} speed={1.75} color="white" />
                  </div>
                ) : (
                  "COMPRAR VOUCHER"
                )}
              </button>
            )}

            {booked && (
              <div className="submitButtonBooked margintop">
                <img
                  className="checkSubmitTick"
                  src="https://img.icons8.com/external-regular-kawalan-studio/96/000000/external-tick-user-interface-regular-kawalan-studio.png"
                  alt=""
                />
              </div>
            )}
                </div>
        
          </div>
         
        )}
      </form>
    </div>
    {booked ? (
                  <div className="CheckOutCont">
                    <div className="checkoutPayOpCont">
                      <div className="checkoutPaymentContOptions">
                        <button
                          type="submit"
                          className="button"
                          onClick={() => {
                            openModal();
                          }}
                        >
                          DETALLE DE ORDEN Y DATOS BANCARIOS
                        </button>
                      </div>

                      <p className="RoomCardsDetailDescription padding">
                        {" "}
                        una vez que abonés enviame el comprobante por whatsapp!
                      </p>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                
                            {modal === false ? (
                              <></>
                            ) : (
                              <VoucherModal
                                modal={modal}
                                closeModal={openModal}
                                name={name}
                                email={email}

                              />
                            )}
                   </section>


   
               <Footer />
             </div>
  )
}
