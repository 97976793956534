import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.scss';

import BookingContextProvider from './components/context/BookingContext';
import CalendarContextProvider from './components/context/CalendarContext';
import MessagesContextProvider from './components/context/MessagesContext';
import RoomsContextProvider from './components/context/RoomsContext';
import CalendarCont from './components/panel/calendar/CalendarCont';
import WeekCalendar from './components/panel/calendar/WeekCalendar';
import Dashboard from './components/panel/Dashboard';
import MessagesListCont from './components/panel/messages/MessagesListCont';
import MetricsListCont from './components/panel/metrics/MetricsListCont';
import BookOrderDashboardListCont from './components/panel/notifications/BookOrderDashboardListCont';
import RoomCardsDetailCont from './components/rooms/RoomCardsDetailCont';
import MicrositesConts from './components/microsites/MicrositesConts';
import RoomsEditCont from './components/panel/roomsEdit/RoomsEditCont';
import RoomsEditDetailCont from './components/panel/roomsEdit/RoomsEditDetailCont';
import RoomsEditAddRoom from './components/panel/roomsEdit/RoomsEditAddRoom';
import PaymentsCont from './components/panel/payments/PaymentsCont';
import SavePaymentData from './components/panel/payments/SavePaymentData';
import ProfileCont from './components/panel/profile/ProfileCont';
import MicrositeEditCont from './components/panel/microsite/MicrositeEditCont';
import CommentsCont from './components/panel/comments/CommentsCont';
import LoginContextProvider from './components/context/LoginContext';
import PricingCont from './components/panel/pricing/PricingCont';
import AgendaCont from './components/panel/agenda/AgendaCont';
import Schedule from './components/panel/roomsEdit/Schedule';
import AddCalendar from './components/panel/roomsEdit/AddCalendar';
import ItemsEditCont from './components/panel/items/ItemsEditCont';
import ForWeekCalendarCont from './components/panel/calendar/ForWeekCalendarCont';
import Checkout from './components/booking/Checkout';
import NewDemand from './components/newCalendar/NewDemand';
import RequestsListCont from './components/panel/requests/RequestsListCont';
import Voucher from './components/microsites/Voucher';


function App() {
  return (

    <BrowserRouter>
      <BookingContextProvider>
        <RoomsContextProvider>
          <CalendarContextProvider>
            <MessagesContextProvider>
              <LoginContextProvider>
                <div className="App">
                  <Routes>
                    <Route path='/' element={<MicrositesConts />} />
                    <Route path='/contactos-turno' element={<NewDemand />} />
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/dashboard/calendar' element={<CalendarCont />} />
                    <Route path='/dashboard/bookcalendar' element={<WeekCalendar />} />
                    <Route path='/dashboard/bookcalendar/weekly' element={<ForWeekCalendarCont />} />
                    {/* <Route path='/checkout/order' element={<BookOrderPage />} /> */}
                    <Route path='/dashboard/messages' element={<MessagesListCont />} />
                    <Route path='/dashboard/agendas' element={<RoomsEditCont />} />
                    <Route path='/dashboard/payments' element={<PaymentsCont />} />
                    <Route path='/dashboard/:roomedit' element={<RoomsEditDetailCont />} />
                    <Route path='/dashboard/:roomedit/schedule' element={<Schedule />} />
                    <Route path='/dashboard/addroom' element={<RoomsEditAddRoom />} />
                    <Route path='/dashboard/pricing' element={<PricingCont />} />
                    <Route path='/dashboard/requests' element={<RequestsListCont />} />
                    <Route path='/dashboard/items' element={<ItemsEditCont />} />
                    <Route path='/checkout/:detailID' element={<RoomCardsDetailCont />} />
          
                    <Route path='/dashboard/metricas' element={<MetricsListCont />} />
                    <Route path='/dashboard/bookings' element={<BookOrderDashboardListCont />} />
                    <Route path='/dashboard/profile' element={<ProfileCont />} />
                    <Route path='/dashboard/microsite' element={<MicrositeEditCont />} />
                    <Route path='/dashboard/microsite/comments' element={<CommentsCont />} />
                    <Route path='/dashboard/addcalendar/:detailID' element={<AddCalendar />} />
                    {/* <Route path='/dashboard/cobros' element={<CobrosListCont />} /> */}
         
                    <Route path='/dashboard/contactos' element={<AgendaCont />} />
                    <Route path='/orden' element={<Checkout />} />
                    <Route path='/payments' element={<SavePaymentData/>} />
                    <Route path='/voucher' element={<Voucher/>} />

                    <Route path='/*' element={<Navigate to='/' replace />}></Route>
                    
                  </Routes>

                </div>
              </LoginContextProvider>
            </MessagesContextProvider>
          </CalendarContextProvider>
        </RoomsContextProvider>
      </BookingContextProvider>

    </BrowserRouter>
  );
}

export default App;

